import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
  canonicalPath?: string;
}

const SEO: React.FC<SEOProps> = ({ 
  title = 'WebItWork - Professionelle Webentwicklung',
  description = 'WebItWork bietet professionelle Webentwicklung, App-Entwicklung und digitale Lösungen für Unternehmen jeder Größe.',
  image = '/images/featured-image.jpg',
  noIndex = false,
  canonicalPath
}) => {
  const location = useLocation();
  const currentLanguage = 'de'; // Festlegung auf Deutsch
  
  // Basis-URL der Website
  const baseUrl = 'https://webitwork.com';
  
  // Bereinige URL-Parameter für die kanonische URL
  const cleanPathname = location.pathname.replace(/\/+$/, ''); // Entferne trailing slashes
  
  // Verwende die angegebene kanonische URL oder erstelle eine saubere URL ohne Parameter
  const canonicalUrl = canonicalPath 
    ? `${baseUrl}${canonicalPath}` 
    : `${baseUrl}${cleanPathname || '/'}`;
  
  // Mobile Optimierungen
  useEffect(() => {
    // Touch-Ziele optimieren
    const optimizeTouchTargets = () => {
      // Minimale Größe für Touch-Ziele (44x44px nach WCAG-Richtlinien)
      const minTouchSize = '44px';
      
      // Selektoren für häufige interaktive Elemente
      const touchTargetSelectors = [
        'a', 'button', 'input', 'select', 'textarea',
        '[role="button"]', '[role="link"]', '[role="menuitem"]'
      ];
      
      // CSS für Touch-Ziel-Optimierung
      const touchTargetCSS = `
        ${touchTargetSelectors.join(', ')} {
          min-height: ${minTouchSize};
          min-width: ${minTouchSize};
        }
        
        /* Verbesserte Touch-Ziel-Abstände */
        a, button, [role="button"], [role="link"] {
          padding: 0.5rem;
          margin: 0.25rem;
        }
        
        /* Verbesserte Fokus-Stile für Tastaturnavigation */
        a:focus, button:focus, input:focus, select:focus, textarea:focus,
        [role="button"]:focus, [role="link"]:focus, [role="menuitem"]:focus {
          outline: 3px solid #2563eb;
          outline-offset: 2px;
        }
      `;
      
      // Füge CSS für Touch-Ziel-Optimierung hinzu
      const styleElement = document.createElement('style');
      styleElement.setAttribute('id', 'touch-target-optimization');
      styleElement.textContent = touchTargetCSS;
      
      // Füge das Stylesheet nur hinzu, wenn es noch nicht existiert
      if (!document.getElementById('touch-target-optimization')) {
        document.head.appendChild(styleElement);
      }
    };
    
    // Führe die Optimierung aus
    optimizeTouchTargets();
    
    // Cleanup beim Unmount
    return () => {
      const styleElement = document.getElementById('touch-target-optimization');
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);
  
  return (
    <Helmet>
      {/* Grundlegende Meta-Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${baseUrl}${image}`} />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={`${baseUrl}${image}`} />
      
      {/* Robots-Anweisungen */}
      {noIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      )}
      
      {/* Viewport-Optimierungen für mobile Geräte */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover" />
      <meta name="theme-color" content="#2563eb" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      
      {/* Canonical URL - konsistent ohne Parameter */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* x-default hreflang für Suchmaschinen */}
      <link 
        rel="alternate" 
        hrefLang="x-default" 
        href={`${baseUrl}${location.pathname}`}
      />
      
      {/* Sprache des Dokuments */}
      <html lang={currentLanguage} />
    </Helmet>
  );
};

export default SEO;
