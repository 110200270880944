import { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verzögertes Laden des Cookie-Banners, um die LCP-Zeit zu verbessern
    const timer = setTimeout(() => {
      // Prüfen, ob der Benutzer bereits Cookies akzeptiert hat
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      
      // Wenn keine Einstellung gefunden wurde, Banner anzeigen
      if (!cookiesAccepted) {
        setShowBanner(true);
      }
    }, 1000); // 1 Sekunde Verzögerung
    
    return () => clearTimeout(timer);
  }, []);

  const acceptCookies = () => {
    // Speichern der Einstellung im localStorage
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  const declineCookies = () => {
    // Speichern der Ablehnung im localStorage
    localStorage.setItem('cookiesAccepted', 'false');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 p-4 md:p-6 border-t border-gray-200"
      role="alert"
      aria-live="polite"
      aria-atomic="true"
    >
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
          <div className="flex-1">
            <h3 className="text-lg font-bold text-dark mb-2">Cookie-Hinweis</h3>
            <p className="text-gray-600 text-sm">
              Wir verwenden Cookies gemäß unserer Datenschutzrichtlinie.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 mt-2 md:mt-0">
            <button 
              onClick={declineCookies}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              aria-label="Cookies ablehnen"
            >
              Ablehnen
            </button>
            <button 
              onClick={acceptCookies}
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors duration-200"
              aria-label="Cookies akzeptieren"
            >
              Akzeptieren
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
