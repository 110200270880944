import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, lazy, Suspense } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import CookieBanner from './components/CookieBanner';
import ScrollToTopButton from './components/ScrollToTopButton';
import SEO from './components/SEO';
import A11yChecker from './components/A11yChecker';
// WebVitalsOptimizer wird nur im Entwicklungsmodus importiert
const WebVitalsOptimizer = process.env.NODE_ENV !== 'production' 
  ? lazy(() => import('./components/WebVitalsOptimizer'))
  : () => null;

// Lazy-loaded components
const Hero = lazy(() => import('./components/Hero'));
const Services = lazy(() => import('./components/Services'));
const Pricing = lazy(() => import('./components/Pricing'));
const About = lazy(() => import('./components/About'));
const TechStack = lazy(() => import('./components/TechStack'));
const Projects = lazy(() => import('./components/Projects'));
const FAQ = lazy(() => import('./components/FAQ'));
const Contact = lazy(() => import('./components/Contact'));
const Imprint = lazy(() => import('./components/Imprint'));
const ServiceDetail = lazy(() => import('./components/ServiceDetail'));
const ProjectDetail = lazy(() => import('./components/ProjectDetail'));
const Privacy = lazy(() => import('./components/Privacy'));
const Terms = lazy(() => import('./components/Terms'));
const LocalSEOLanding = lazy(() => import('./components/LocalSEOLanding'));
const NotFound = lazy(() => import('./components/NotFound'));

// Loading-Komponente für Suspense mit fester Höhe, um Layout Shifts zu vermeiden
const Loading = () => (
  <div className="flex justify-center items-center min-h-[600px]">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
  </div>
);

// ScrollToTop-Komponente, die bei jedem Routenwechsel automatisch nach oben scrollt
// und Anker-Links korrekt verarbeitet
const ScrollToTop = () => {
  const { pathname, hash } = useLocation();
  
  useEffect(() => {
    // Wenn ein Hash (Anker) vorhanden ist, scrolle zu diesem Element
    if (hash) {
      // Verzögerung hinzufügen, um sicherzustellen, dass die Komponenten gerendert sind
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      // Wenn kein Hash vorhanden ist, scrolle nach oben
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);
  
  return null;
};

// Homepage-Komponente, die alle Hauptsektionen enthält
const HomePage = () => {
  // Preload wichtige Komponenten, um Layout Shifts zu reduzieren
  useEffect(() => {
    // Sofort die wichtigsten Komponenten vorladen
    const preloadComponents = async () => {
      try {
        // Parallel laden der wichtigsten Komponenten
        await Promise.all([
          import('./components/Hero'),
          import('./components/Footer')
        ]);
      } catch (error) {
        console.error('Fehler beim Vorladen der Komponenten:', error);
      }
    };
    
    preloadComponents();
  }, []);
  
  // Scrolle zu Anker-Elementen, wenn die Homepage geladen wird und ein Hash in der URL vorhanden ist
  // oder wenn ein Scroll-Ziel in sessionStorage gespeichert ist
  useEffect(() => {
    // Verzögerung hinzufügen, um sicherzustellen, dass alle Komponenten gerendert sind
    const timer = setTimeout(() => {
      // Prüfe zuerst, ob ein Hash in der URL vorhanden ist
      const hash = window.location.hash;
      if (hash) {
        const elementId = hash.substring(1); // Entferne das # vom Anfang
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } 
      // Wenn kein Hash vorhanden ist, prüfe, ob ein Scroll-Ziel in sessionStorage gespeichert ist
      else {
        const scrollTarget = sessionStorage.getItem('scrollTarget');
        if (scrollTarget) {
          const element = document.getElementById(scrollTarget);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            // Entferne das Scroll-Ziel aus sessionStorage, damit es nicht erneut verwendet wird
            sessionStorage.removeItem('scrollTarget');
          }
        }
      }
    }, 800); // 800ms Verzögerung, um sicherzustellen, dass alle Komponenten gerendert sind
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <Suspense fallback={<Loading />}>
      <div>
        <Hero />
        <Services />
        <Pricing />
        <About />
        <TechStack />
        <Projects />
        <FAQ />
        <Contact />
      </div>
    </Suspense>
  );
};

// AppContent-Komponente, die den Inhalt der App enthält und ScrollToTop verwendet
const AppContent = () => {

  // WebSite-Schema für die Startseite
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Webitwork",
    "url": "https://webitwork.com",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://webitwork.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    "inLanguage": "de"
  };

  return (
    <div className="min-h-screen flex flex-col">
      <ScrollToTop />
      {process.env.NODE_ENV !== 'production' && <WebVitalsOptimizer />}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
      </Helmet>
      <SEO />
      <Header />
      <main className="flex-grow" style={{ minHeight: '800px', height: 'auto', contain: 'layout' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/impressum" element={
            <Suspense fallback={<Loading />}>
              <Imprint />
            </Suspense>
          } />
          <Route path="/services/:slug" element={
            <Suspense fallback={<Loading />}>
              <ServiceDetail />
            </Suspense>
          } />
          <Route path="/projects/:id" element={
            <Suspense fallback={<Loading />}>
              <ProjectDetail />
            </Suspense>
          } />
          <Route path="/datenschutz" element={
            <Suspense fallback={<Loading />}>
              <Privacy />
            </Suspense>
          } />
          <Route path="/agb" element={
            <Suspense fallback={<Loading />}>
              <Terms />
            </Suspense>
          } />
          <Route path="/local/:ort/:service" element={
            <Suspense fallback={<Loading />}>
              <LocalSEOLanding />
            </Suspense>
          } />
          <Route path="*" element={
            <Suspense fallback={<Loading />}>
              <NotFound />
            </Suspense>
          } />
        </Routes>
      </main>
      <Footer />
      <CookieBanner />
      <ScrollToTopButton />
      {process.env.NODE_ENV !== 'production' && <A11yChecker />}
    </div>
  );
};

function App() {
  return (
    <HelmetProvider>
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
